import React from 'react';
import Flip from 'react-reveal/Flip';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// Global Library
import { ContentContainerDiv } from '../../../../components/Containers';
import { StandardButton } from '../../../../components/interfaces/StandardButton';
// Module Library
import { SearchBarContainerDiv } from '../containers/index';
import {
  searchSpotify,
  setFetchingStarted,
  updateArtistQuery,
} from '../../actions/search';

export const SearchBar = () => {
  const dispatch = useDispatch();

  const { artistQuery } = useSelector(
    (state) => ({
      artistQuery: state.searchResults.artistQuery,
    }),
    shallowEqual
  );

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButton();
    }
  };

  const handleUserInput = (event) => {
    dispatch(updateArtistQuery(event.target.value));
  };

  const handleSearchButton = () => {
    if (artistQuery.length > 0) {
      dispatch(setFetchingStarted());
      dispatch(searchSpotify(artistQuery));
    } else {
      alert('Please enter at least one character');
    }
  };

  return (
    <Flip bottom>
      <ContentContainerDiv>
        <SearchBarContainerDiv>
          <input
            type='search'
            onChange={handleUserInput}
            onKeyPress={handleEnterKeyPress}
            placeholder='Search for an Artist'
            defaultValue={artistQuery ? artistQuery : null}
          />
          <StandardButton text='Search' onClick={handleSearchButton} />
        </SearchBarContainerDiv>
      </ContentContainerDiv>
    </Flip>
  );
};
