import React from 'react';
import Slide from 'react-reveal/Slide';

export const Artist = ({ artist }) => {
  if (!artist) return null;

  const { images, name, genres, followers } = artist;

  return (
    <Slide left>
      <div id='artistDetails'>
        <img src={images[0] && images[0].url} alt='artist-profile' />
        <h4>{name}</h4>
        <h4>Followers: {followers.total}</h4>
        <h4>Genres: {genres[0] ? genres[0] : 'N/A'}</h4>
      </div>
    </Slide>
  );
};
