import {
  SET_GAME_STARTED,
  SET_INSTRUCTIONS_HIDDEN,
  TRY_AGAIN
} from "../actions/types";

const DEFAULT_SETTINGS = {
  gameStarted: false,
  instructionsHidden: true
};

const settingsReducer = (state = DEFAULT_SETTINGS, action) => {
  switch (action.type) {
    case TRY_AGAIN:
      return DEFAULT_SETTINGS;
    case SET_GAME_STARTED:
      return { ...state, gameStarted: action.gameStarted };
    case SET_INSTRUCTIONS_HIDDEN:
      return { ...state, instructionsHidden: action.instructionsHidden };
    default:
      return state;
  }
};

export default settingsReducer;
