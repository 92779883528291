import React from "react";
import { connect } from "react-redux";

const correctGuessesRecordKey = "CORRECT_GUESSES_RECORD_N123";

const checkRecord = correctGuesses => {
  const record = Number(localStorage.getItem(correctGuessesRecordKey));

  if (correctGuesses > record) {
    localStorage.setItem(correctGuessesRecordKey, correctGuesses);

    return { record: correctGuesses, isNewRecord: true };
  }

  return { isNewRecord: false, record };
};

const GameStats = ({ correctGuesses, instructionsHidden, remaining }) => {
  const cardText = remaining === 1 ? "card" : "cards";
  const guessText = correctGuesses === 1 ? "guess" : "guesses";
  const { isNewRecord, record } = checkRecord(correctGuesses);
  const recordLabel = isNewRecord ? "🎉 New Record" : "Current Record";

  return (
    <div id="gameStats">
      {!instructionsHidden ? (
        <React.Fragment>
          <h3>How to play</h3>
          <h4>What will the next card drawn be? Even or Odd?</h4>
          <h4>Make your choice below.</h4>
          <h4>Ace = 1, J = 11, Q = 12, K = 13</h4>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>Game Stats</h3>
          <h4>
            {remaining} {cardText} remaining
          </h4>
          <h4>
            {correctGuesses} correct {guessText}
          </h4>
          <h4>
            {recordLabel}: {record}
          </h4>
        </React.Fragment>
      )}
    </div>
  );
};

export default connect(
  ({
    settings: { instructionsHidden },
    deck: { remaining },
    gameStats: { correctGuesses }
  }) => ({
    correctGuesses,
    instructionsHidden,
    remaining
  })
)(GameStats);
