import React from 'react';
import Slide from 'react-reveal/Slide';
// Global library
import {
  ContentContainerDiv,
  ContentDiv,
} from '../../../components/Containers';
import { ContentTitle } from '../../../components/layouts/ContentTitle';
import Title from '../../../components/layouts/Title';
// Module library
import INTRO_TEXT from '../data/introText';

const IntroParagraphs = (props) => {
  const { text } = props.IntroParagraphs;

  return <p>{text}</p>;
};

export const LandingPageContainer = () => {
  return (
    <ContentDiv>
      <ContentTitle title='My Portfolio' />
      <Title />
      <Slide bottom>
        <ContentContainerDiv>
          {INTRO_TEXT.map((TEXT) => (
            <IntroParagraphs key={TEXT.id} IntroParagraphs={TEXT} />
          ))}
        </ContentContainerDiv>
      </Slide>
    </ContentDiv>
  );
};
