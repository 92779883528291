import { ENTERTAINMENT_NEWS, NEWS_OPTIONS, TECH_NEWS } from "../actions/types";
import fetchStates from "./fetchStates";

const DEFAULT_NEWS = {
  entertainmentNews: "",
  fetchState: "",
  hasEntertainmentNews: false,
  hasTechNews: false,
  message: "",
  showNews: false,
  techNews: "",
  viewNewsModule: ""
};

const newsReducer = (state = DEFAULT_NEWS, action) => {
  switch (action.type) {
    case ENTERTAINMENT_NEWS.RENDER_STORE_NEWS:
      return { ...state, viewNewsModule: NEWS_OPTIONS.E_WEEKLY };
    case ENTERTAINMENT_NEWS.FETCH_SUCCESS:
      return {
        ...state,
        fetchState: fetchStates.success,
        hasEntertainmentNews: true,
        entertainmentNews: action.entertainmentNews,
        showNews: true,
        viewNewsModule: NEWS_OPTIONS.E_WEEKLY
      };
    case ENTERTAINMENT_NEWS.FETCH_ERROR:
      return {
        ...state,
        message: action.message,
        fetchState: fetchStates.error
      };
    case TECH_NEWS.RENDER_STORE_NEWS:
      return { ...state, viewNewsModule: NEWS_OPTIONS.TECH_CRUNCH };
    case TECH_NEWS.FETCH_SUCCESS:
      return {
        ...state,
        fetchState: fetchStates.success,
        hasTechNews: true,
        showNews: true,
        techNews: action.techNews,
        viewNewsModule: NEWS_OPTIONS.TECH_CRUNCH
      };
    case TECH_NEWS.FETCH_ERROR:
      return {
        ...state,
        message: action.message,
        fetchState: fetchStates.error
      };
    case NEWS_OPTIONS.TRY_AGAIN:
      return DEFAULT_NEWS;
    default:
      return state;
  }
};

export default newsReducer;
