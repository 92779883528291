import { AUDIO_PLAYER } from "./types";

export const playAudio = (audioPlayer, previewUrl) => {
  console.log("------------------------------------");
  console.log(previewUrl);
  console.log("------------------------------------");
  return {
    type: AUDIO_PLAYER.PLAY_AUDIO,
    audioPlayer: audioPlayer,
    playingPreviewUrl: previewUrl
  };
};
export const stopAudio = () => {
  return {
    type: AUDIO_PLAYER.STOP_AUDIO
  };
};
