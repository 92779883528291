import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// Global Library
import {
  ContentDiv,
  ContentContainerDiv,
} from '../../../../components/Containers';
import { ContentTitle } from '../../../../components/layouts/ContentTitle';
import { LoadingSpinner } from '../../../../components/interfaces/LoadingSpinner';
// Module Library
import { SearchBar } from '../interfaces/SearchBar';
import { SearchResults } from './SearchResults';
import { TITLE } from '../../actions/types';
import { fetchStates } from '../../reducers/fetchStates';

export const SpotifyArtistSearchApp = () => {
  const { fetchState } = useSelector(
    (state) => ({
      fetchState: state.searchResults.fetchState,
    }),
    shallowEqual
  );
  return (
    <ContentDiv>
      <ContentTitle title={TITLE} />
      {fetchState === fetchStates.fetching ? (
        <ContentContainerDiv>
          <LoadingSpinner />
        </ContentContainerDiv>
      ) : (
        <>
          <SearchBar />
          <SearchResults />
        </>
      )}
    </ContentDiv>
  );
};
