import React from 'react';
import Slide from 'react-reveal/Slide';
// Global library
import { ContentContainerDiv } from '../../../components/Containers';
// Module library
import MEET_NATE_BIO_PARAGRAPHS from '../data/meetNateBio';

const BioParagraphs = (props) => {
  const { text } = props.BioParagraphs;

  return <p>{text}</p>;
};

export const Bio = () => (
  <Slide bottom>
    <ContentContainerDiv>
      {MEET_NATE_BIO_PARAGRAPHS.map((MEET_NATE_BIO_PARAGRAPH) => (
        <BioParagraphs
          key={MEET_NATE_BIO_PARAGRAPH.id}
          BioParagraphs={MEET_NATE_BIO_PARAGRAPH}
        />
      ))}
    </ContentContainerDiv>
  </Slide>
);
