import { NEWS_OPTIONS, TECH_NEWS } from './types';
import { API_ADDRESS } from '../../../data/globalsVariables';

export const fetchNewsError = (error) => {
  return { type: TECH_NEWS.FETCH_ERROR, message: error.message };
};

export const fetchNewsSuccess = (newsJson) => {
  return { type: TECH_NEWS.FETCH_SUCCESS, techNews: newsJson };
};

export const fetchTechNews = () => (dispatch) => {
  return fetch(`${API_ADDRESS}/v1/news-feed/tech-crunch-news`)
    .then((response) => {
      if (response.status === 429) {
        throw new Error('Too many requests, please check back in 15 mins');
      } else if (response.status !== 200) {
        throw new Error('Unsuccessful request to news api');
      }

      return response.json();
    })
    .then((json) => dispatch(fetchNewsSuccess(json)))
    .catch((error) => dispatch(fetchNewsError(error)));
};

export const renderStoreTechNews = () => {
  return { type: TECH_NEWS.RENDER_STORE_NEWS };
};

export const reloadAfterError = () => {
  return { type: NEWS_OPTIONS.TRY_AGAIN };
};
