import { combineReducers } from "redux";
import deckReducer from "./deck";
import gameStatsReducer from "./gameStats";
import settingsReducer from "./settings";

export default combineReducers({
  deck: deckReducer,
  gameStats: gameStatsReducer,
  settings: settingsReducer
});
