import Flip from 'react-reveal/Flip';
import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
// Global library
import {
  ButtonWrapperDiv,
  ContentContainerDiv,
  ContentDiv,
} from '../../components/Containers';
import { ContentTitle } from '../../components/layouts/ContentTitle';
import { LoadingSpinner } from '../../components/interfaces/LoadingSpinner';
import { StandardButton } from '../../components/interfaces/StandardButton';
// Module library
import { JokesLayout } from './components/layouts/JokesLayout';

export class Jokes extends Component {
  state = {
    error: false,
    errorMessage: {},
    fadeOut: false,
    isLoading: true,
    joke: {},
    jokes: [],
    removeFirstJoke: false,
  };

  componentDidMount() {
    setTimeout(() => {
      fetch(
        'https://nova-joke-api.netlify.app/.netlify/functions/index/random_joke'
      )
        .then((response) => response.json())
        .then((json) => this.setState({ isLoading: false, joke: json }))
        .catch((error) =>
          this.setState({ error: true, errorMessage: error, isLoading: false })
        );
    }, 1000);
  }

  fetchOneJoke = () => {
    this.setState({
      error: false,
      errorMessage: {},
      fadeOut: true,
      isLoading: true,
    });

    setTimeout(() => {
      this.setState({ fadeOut: false, removeFirstJoke: false });
      fetch(
        'https://nova-joke-api.netlify.app/.netlify/functions/index/random_joke'
      )
        .then((response) => response.json())
        .then((json) => this.setState({ isLoading: false, joke: json }))
        .catch((error) =>
          this.setState({ error: true, errorMessage: error, isLoading: false })
        );
    }, 2000);
  };

  fetchTenJokes = () => {
    this.setState({
      error: false,
      errorMessage: {},
      fadeOut: true,
      isLoading: true,
    });

    setTimeout(() => {
      this.setState({ fadeOut: false, removeFirstJoke: true });
      fetch(
        'https://nova-joke-api.netlify.app/.netlify/functions/index/random_ten'
      )
        .then((response) => response.json())
        .then((json) => this.setState({ isLoading: false, jokes: json }))
        .catch((error) =>
          this.setState({ error: true, errorMessage: error, isLoading: false })
        );
    }, 2000);
  };

  render() {
    const {
      error,
      errorMessage,
      fadeOut,
      isLoading,
      joke,
      jokes,
      removeFirstJoke,
    } = this.state;
    return isLoading ? (
      <ContentDiv>
        <ContentTitle title='Joke of the Day' />
        <Flip bottom>
          <ContentContainerDiv>
            <LoadingSpinner />
          </ContentContainerDiv>
        </Flip>
      </ContentDiv>
    ) : (
      <ContentDiv>
        <ContentTitle title='Joke of the Day' />
        <Flip bottom>
          <ContentContainerDiv>
            <ButtonWrapperDiv>
              <StandardButton
                text='Get Another Joke'
                onClick={this.fetchOneJoke}
              />
              <StandardButton
                text='Get 10 Jokes'
                onClick={this.fetchTenJokes}
              />
            </ButtonWrapperDiv>
          </ContentContainerDiv>
        </Flip>
        <Slide bottom>
          <ContentContainerDiv>
            {error ? (
              <h2>Error Message: {errorMessage.message}</h2>
            ) : (
              <JokesLayout
                fade={fadeOut}
                joke={removeFirstJoke ? jokes : joke}
                removeFirstJoke={removeFirstJoke}
              />
            )}
          </ContentContainerDiv>
        </Slide>
      </ContentDiv>
    );
  }
}
