import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchNewDeck } from '../../actions/deck';
import { startGame } from '../../actions/settings';
import {
  ButtonWrapperDiv,
  ContentContainerDiv,
} from '../../../../components/Containers';
import Flip from 'react-reveal/Flip';
import { StandardButton } from '../../../../components/interfaces/StandardButton';

class StartGameButton extends Component {
  startGame = () => {
    const { fetchNewDeck, startGame } = this.props;

    startGame();
    fetchNewDeck();
  };

  render() {
    return (
      <Flip bottom>
        <ContentContainerDiv>
          <ButtonWrapperDiv>
            <StandardButton text='Start A New Game' onClick={this.startGame} />
          </ButtonWrapperDiv>
        </ContentContainerDiv>
      </Flip>
    );
  }
}

const componentConnector = connect(null, {
  startGame,
  fetchNewDeck,
});

export default componentConnector(StartGameButton);
