import React from 'react';
import { ButtonWrapperDiv } from '../../../../components/Containers';
import { StandardButton } from '../../../../components/interfaces/StandardButton';

const CancelButton = ({ cancelGame }) => {
  return (
    <ButtonWrapperDiv>
      <StandardButton text='Cancel Game' onClick={cancelGame} />
    </ButtonWrapperDiv>
  );
};

export default CancelButton;
