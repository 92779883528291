export const TECH_NEWS = {
  RENDER_STORE_NEWS: "RENDER_STORE_TECH_NEWS",
  FETCH_SUCCESS: "TECH_NEWS_FETCH_SUCCESS",
  FETCH_ERROR: "TECH_NEWS_FETCH_ERROR"
};

export const ENTERTAINMENT_NEWS = {
  RENDER_STORE_NEWS: "RENDER_STORE_ENTERTAINMENT_NEWS",
  FETCH_SUCCESS: "ENTERTAINMENT_NEWS_FETCH_SUCCESS",
  FETCH_ERROR: "ENTERTAINMENT_NEWS_FETCH_ERROR"
};

export const NEWS_OPTIONS = {
  TECH_CRUNCH: "Tech Crunch",
  TRY_AGAIN: "TRY_AGAIN",
  E_WEEKLY: "Entertainment Weekly"
};

export const TITLE = "The Latest News";
