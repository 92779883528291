import React from 'react';

export const LightboxHeader = ({ images, currentIndex, onClose, text }) => {
  return (
    <div className='lightboxHeader'>
      <div className='leftSide'>
        <div className='lightboxHeaderText'>{images[currentIndex].alt}</div>
        <div className='lightboxHeaderText'>
          {currentIndex + 1} / {images.length}
        </div>
      </div>
      <div className='rightSide'>
        <button onClick={onClose}>
          <div id='closeButtonBox'>
            <div className='closeButtonLineBottomLeftToTopRight'>
              <div className='closeButtonLineTopLeftToBottomRight'></div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
