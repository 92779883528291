import React from 'react';
import { connect } from 'react-redux';
import { fetchNewCard } from '../../actions/drawCard';
import { ButtonWrapperDiv } from '../../../../components/Containers';
import { StandardButton } from '../../../../components/interfaces/StandardButton';

const DrawCard = ({ deck_id, fetchNewCard }) => {
  return (
    <ButtonWrapperDiv>
      <StandardButton text='Draw A Card' onClick={fetchNewCard(deck_id)} />
    </ButtonWrapperDiv>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewCard: (deck_id) => () => dispatch(fetchNewCard(deck_id)),
  };
};

export default connect(
  ({ deck: { deck_id } }) => ({ deck_id }),
  mapDispatchToProps
)(DrawCard);
