import React from "react";
import { connect } from "react-redux";
import Slide from "react-reveal/Slide";

const TechNewsDisplay = ({ hasTechNews, techNews }) => {
  return hasTechNews ? (
    <Slide left cascade>
      <div className="newsContainer">
        <h2>Tech Crunch</h2>
        {techNews.map(article => {
          const { content, id, publishedAt, title, url, urlToImage } = article;
          console.log(content);
          return (
            <div className="newsArticle" key={id}>
              <div className="articleHeadline">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
                {content ? (
                  <h4 className="articleContent">
                    {content.split(" [")[0]}
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      read more
                    </a>
                  </h4>
                ) : (
                  <h4 className="articleContent">
                    No article preview is available.
                  </h4>
                )}
                <h5>{new Date(publishedAt).toLocaleString()}</h5>
              </div>
              <div className="articleImage">
                <img src={urlToImage} alt="article" />
              </div>
            </div>
          );
        })}
      </div>
    </Slide>
  ) : (
    <div className="newsContainer">
      <h2>Tech Crunch</h2>
    </div>
  );
};

export default connect(({ news: { hasTechNews, techNews } }) => ({
  hasTechNews,
  techNews
}))(TechNewsDisplay);
