import React from 'react';
import { ContentContainerDiv } from '../Containers';
import Slide from 'react-reveal/Slide';

const ProjectParagraphs = ({ projectDescriptionText }) => {
  const { text } = projectDescriptionText;

  return <p>{text}</p>;
};

export const ProjectDescription = ({ projectDescriptionArray }) => {
  return (
    <Slide bottom>
      <ContentContainerDiv>
        {projectDescriptionArray.map((projectDescriptionText) => (
          <ProjectParagraphs
            key={projectDescriptionText.id}
            projectDescriptionText={projectDescriptionText}
          />
        ))}
      </ContentContainerDiv>
    </Slide>
  );
};
