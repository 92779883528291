import { PROJECT_TRACKER_IMAGES } from "./projectTrackerImages";

export const PROJECT_TRACKER_DATA = {
  title: "Project Tracker",
  desc: [
    {
      id: 1,
      text:
        "Project Tracker is a project management and billing system for hourly billing of custom forms projects for MacPractice clients, as well as an interface for Forms Specialists to track these projects from start to finish."
    },
    {
      id: 2,
      text:
        "I started designing and building this app in January 2013. The app was built with a focus on increasing productivity and streamlining the workflow of my Forms Specialists, as well as creating a new revenue stream for the company that previously didn't exist. "
    },
    {
      id: 3,
      text:
        "I started with a team of 4 to tackle this process.By the end of May 2013 we were in beta, and by the first of August we were totally live with the app, with a department of 14 using it daily. "
    },
    {
      id: 4,
      text:
        "It utilizes HTML, Ruby, JavaScript, jQuery, CSS and a MySQL database. This interface includes the ability to record time spent on a project with a built in timer that will allow the Forms Specialist to start and stop work. An invoicing system and admin interface, for such things as time edits and reports are also included."
    }
  ],

  previews: [
    {
      id: 1,
      alt: "The login page.",
      preview: PROJECT_TRACKER_IMAGES.projTrack1,
      src: PROJECT_TRACKER_IMAGES.projTrack1Full
    },
    {
      id: 2,
      alt:
        "The Dashboard where the user can see all open projects, and sort as desired.",
      preview: PROJECT_TRACKER_IMAGES.projTrack2,
      src: PROJECT_TRACKER_IMAGES.projTrack2Full
    },
    {
      id: 3,
      alt: "The users calendar is also available via the Dashboard.",
      preview: PROJECT_TRACKER_IMAGES.projTrack3,
      src: PROJECT_TRACKER_IMAGES.projTrack3Full
    },
    {
      id: 4,
      alt:
        "The Mission Control panel dropped down, where the user can time their projects, manage their to-do list, and view notes for the project at a glance.",
      preview: PROJECT_TRACKER_IMAGES.projTrack4,
      src: PROJECT_TRACKER_IMAGES.projTrack4Full
    },
    {
      id: 5,
      alt: "The pop-up window when the user adds a note to a project.",
      preview: PROJECT_TRACKER_IMAGES.projTrack5,
      src: PROJECT_TRACKER_IMAGES.projTrack5Full
    },
    {
      id: 6,
      alt: "The Project overview area where you can bill for the hours spent.",
      preview: PROJECT_TRACKER_IMAGES.projTrack6,
      src: PROJECT_TRACKER_IMAGES.projTrack6Full
    },
    {
      id: 7,
      alt:
        "The prepared statement, ready to be emailed to the client for billing.",
      preview: PROJECT_TRACKER_IMAGES.projTrack7,
      src: PROJECT_TRACKER_IMAGES.projTrack7Full
    },
    {
      id: 8,
      alt: "Meeting notes for tracking weekly meetings.",
      preview: PROJECT_TRACKER_IMAGES.projTrack8,
      src: PROJECT_TRACKER_IMAGES.projTrack8Full
    }
  ]
};

export default PROJECT_TRACKER_DATA;
