import React from 'react';
import { App } from './components/App';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const store = createStore(rootReducer, applyMiddleware(thunk));

store.subscribe(() => console.log('store.getState', store.getState()));

export const MusicMaster = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
