export const SET_GAME_STARTED = "SET_GAME_STARTED";
export const SET_INSTRUCTIONS_HIDDEN = "SET_INSTRUCTIONS_HIDDEN";
export const SET_GUESS = "SET_GUESS";
export const TRY_AGAIN = "TRY_AGAIN";

export const DECK = {
  FETCH_SUCCESS: "DECK_FETCH_SUCCESS",
  FETCH_ERROR: "DECK_FETCH_ERROR"
};
export const DECK_DRAW = {
  FETCH_SUCCESS: "DECK_DRAW_FETCH_SUCCESS",
  FETCH_ERROR: "DECK_DRAW_FETCH_ERROR"
};
