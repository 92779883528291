import { DECK, DECK_DRAW, SET_GAME_STARTED, TRY_AGAIN } from "../actions/types";
import fetchStates from "./fetchStates";

const DEFAULT_DECK = {
  cards: [],
  deck_id: "",
  fetchState: "",
  message: "",
  remaining: 0
};

const deckReducer = (state = DEFAULT_DECK, action) => {
  let remaining, deck_id, cards;

  switch (action.type) {
    case TRY_AGAIN:
      return DEFAULT_DECK;
    case SET_GAME_STARTED:
      if (!action.gameStarted) return DEFAULT_DECK;
    /* falls through */
    case DECK.FETCH_SUCCESS:
      ({ remaining, deck_id } = action);
      return { ...state, remaining, deck_id, fetchState: fetchStates.success };
    case DECK.FETCH_ERROR:
      return {
        ...state,
        message: action.message,
        fetchState: fetchStates.error
      };
    case DECK_DRAW.FETCH_SUCCESS:
      ({ cards, remaining } = action);
      return { ...state, cards, remaining, fetchState: fetchStates.sucess };
    case DECK_DRAW.FETCH_ERROR:
      return {
        ...state,
        message: action.message,
        fetchState: fetchStates.error
      };
    default:
      return state;
  }
};

export default deckReducer;
