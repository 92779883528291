import React from 'react';
import Flip from 'react-reveal/Flip';

export const ContentTitle = ({ title }) => {
  return (
    <h1 className='contentTitle'>
      <Flip right cascade>
        {title}
      </Flip>
    </h1>
  );
};
