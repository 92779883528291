import { ENTERTAINMENT_NEWS } from './types';
import { API_ADDRESS } from '../../../data/globalsVariables';

export const fetchNewsError = (error) => {
  return { type: ENTERTAINMENT_NEWS.FETCH_ERROR, message: error.message };
};

export const fetchNewsSuccess = (newsJson) => {
  return {
    type: ENTERTAINMENT_NEWS.FETCH_SUCCESS,
    entertainmentNews: newsJson,
  };
};

export const fetchEntertainmentNews = () => (dispatch) => {
  return fetch(`${API_ADDRESS}/v1/news-feed/entertainment-news`)
    .then((response) => {
      if (response.status === 429) {
        throw new Error('Too many requests, please check back in 15 mins');
      } else if (response.status !== 200) {
        throw new Error('Unsuccessful request to news api');
      }

      return response.json();
    })
    .then((json) => dispatch(fetchNewsSuccess(json)))
    .catch((error) => dispatch(fetchNewsError(error)));
};

export const renderStoreEntertainmentNews = () => {
  return { type: ENTERTAINMENT_NEWS.RENDER_STORE_NEWS };
};
