import { PAN_TECH_IMAGES } from "./panTechImages";

export const PAN_TECH_DATA = {
  title: "Panology Website",
  desc: [
    {
      id: 1,
      text:
        "I completed a total redesign and update of a local tech company website."
    },
    {
      id: 2,
      text: "Responsive design for mobile phones and tablets."
    },
    {
      id: 3,
      text:
        "Use of Bootstrap, CSS3 and jQuery to be responsive and accessible across devices."
    },
    {
      id: 4,
      text:
        "Integrated contact form with JIRA to track customer contact and issues."
    }
  ],
  previews: [
    {
      id: 1,
      alt:
        'Home page utilizing a bootstrap carousel and contact us button "above the fold".',
      preview: PAN_TECH_IMAGES.panTech1,
      src: PAN_TECH_IMAGES.panTech1Full
    },
    {
      id: 2,
      alt: "Business solutions page.",
      preview: PAN_TECH_IMAGES.panTech2,
      src: PAN_TECH_IMAGES.panTech2Full
    },
    {
      id: 3,
      alt: "Contact form - integrated with JIRA.",
      preview: PAN_TECH_IMAGES.panTech3,
      src: PAN_TECH_IMAGES.panTech3Full
    },
    {
      id: 4,
      alt: "Mobile view of contact us form.",
      preview: PAN_TECH_IMAGES.panTech4,
      src: PAN_TECH_IMAGES.panTech4Full
    },
    {
      id: 5,
      alt: "Mobile view of about us page.",
      preview: PAN_TECH_IMAGES.panTech5,
      src: PAN_TECH_IMAGES.panTech5Full
    }
  ]
};

export default PAN_TECH_DATA;
