import React, { Component } from "react";
import { NEWS_OPTIONS } from "../../actions/types";
import { connect } from "react-redux";
import {
  fetchEntertainmentNews,
  renderStoreEntertainmentNews
} from "../../actions/entertainmentNews";
import { fetchTechNews, renderStoreTechNews } from "../../actions/techNews";
import Flip from "react-reveal/Flip";
import Toggle from "../../../../components/interfaces/ToggleRadioButtons";

const { E_WEEKLY, TECH_CRUNCH } = NEWS_OPTIONS;
const newsOptions = [TECH_CRUNCH, E_WEEKLY];

class ToggleNewsButtons extends Component {
  toggleNewsHandler(newsModule) {
    const {
      fetchEntertainmentNews,
      fetchTechNews,
      hasEntertainmentNews,
      hasTechNews,
      renderStoreEntertainmentNews,
      renderStoreTechNews
    } = this.props;

    switch (newsModule) {
      case TECH_CRUNCH:
        return hasTechNews ? renderStoreTechNews() : fetchTechNews();
      case E_WEEKLY:
        return hasEntertainmentNews
          ? renderStoreEntertainmentNews()
          : fetchEntertainmentNews();
      default:
        return null;
    }
  }

  render() {
    const { viewNewsModule } = this.props;

    return (
      <Flip bottom>
        <Toggle
          items={newsOptions}
          checked={viewNewsModule}
          name="newRadioButtons"
          onToggle={this.toggleNewsHandler.bind(this)}
        />
      </Flip>
    );
  }
}

const mapStateToProps = state => {
  const {
    news: { hasEntertainmentNews, hasTechNews, viewNewsModule }
  } = state;

  return { hasEntertainmentNews, hasTechNews, viewNewsModule };
};

const componentConnector = connect(
  mapStateToProps,
  {
    fetchEntertainmentNews,
    fetchTechNews,
    renderStoreEntertainmentNews,
    renderStoreTechNews
  }
);

export default componentConnector(ToggleNewsButtons);
