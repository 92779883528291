import mobileWef1 from "./mobileWef1.png";
import mobileWef2 from "./mobileWef2.png";
import mobileWef3 from "./mobileWef3.png";
import mobileWef4 from "./mobileWef4.png";
import mobileWef5 from "./mobileWef5.png";
import mobileWef6 from "./mobileWef6.png";
import mobileWef7 from "./mobileWef7.png";
import mobileWef1Full from "./mobileWef1Full.png";
import mobileWef2Full from "./mobileWef2Full.png";
import mobileWef3Full from "./mobileWef3Full.png";
import mobileWef4Full from "./mobileWef4Full.png";
import mobileWef5Full from "./mobileWef5Full.png";
import mobileWef6Full from "./mobileWef6Full.png";
import mobileWef7Full from "./mobileWef7Full.png";

export const MOBILE_WEF_IMAGES = {
  mobileWef1: mobileWef1,
  mobileWef2: mobileWef2,
  mobileWef3: mobileWef3,
  mobileWef4: mobileWef4,
  mobileWef5: mobileWef5,
  mobileWef6: mobileWef6,
  mobileWef7: mobileWef7,
  mobileWef1Full: mobileWef1Full,
  mobileWef2Full: mobileWef2Full,
  mobileWef3Full: mobileWef3Full,
  mobileWef4Full: mobileWef4Full,
  mobileWef5Full: mobileWef5Full,
  mobileWef6Full: mobileWef6Full,
  mobileWef7Full: mobileWef7Full
};

export default MOBILE_WEF_IMAGES;
