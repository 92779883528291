export const API_ADDRESS =
  document.location.origin === 'http://localhost:1234'
    ? 'http://localhost:3000'
    : document.location.origin;

export const ARTIST_SEARCH = {
  FETCH_ERROR: 'FETCH_ERROR',
  NO_SEARCH_RESULTS: 'NO_SEARCH_RESULTS',
  SEARCH_RESULTS_FOUND: 'SEARCH_RESULTS_FOUND',
  SEARCH_STARTED: 'SEARCH_STARTED',
  TRY_AGAIN: 'TRY_AGAIN',
  USER_TYPED_INTO_SEARCH: 'USER_TYPED_INTO_SEARCH',
};

export const AUDIO_PLAYER = {
  STOP_AUDIO: 'STOP_AUDIO',
  PLAY_AUDIO: 'PLAY_AUDIO',
};

export const TITLE = 'Spotify Artist Search';
