import React from 'react';
// Global library
import {
  ContentContainerDiv,
  ContentDiv,
} from '../../../components/Containers';
import { ContentTitle } from '../../../components/layouts/ContentTitle';
// Module library

const RouteNotFoundContainer = () => {
  return (
    <ContentDiv>
      <ContentTitle title='Error' />
      <ContentContainerDiv>
        <h3>404: Page Not Found</h3>
      </ContentContainerDiv>
    </ContentDiv>
  );
};

export default RouteNotFoundContainer;
