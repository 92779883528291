import { DECK_DRAW } from './types';
import { API_ADDRESS } from './../../../data/globalsVariables';

export const fetchNewCard = (deck_id) => (dispatch) => {
  return fetch(`${API_ADDRESS}/v1/even-or-odd/deck/${deck_id}/draw`)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Unsuccessful request to deck of cards api');
      }
      return response.json();
    })
    .then((json) => {
      dispatch({
        type: DECK_DRAW.FETCH_SUCCESS,
        cards: json.cards,
        remaining: json.remaining,
      });
    })
    .catch((error) =>
      dispatch({ type: DECK_DRAW.FETCH_ERROR, message: error.message })
    );
};
