import { ARTIST_SEARCH } from '../actions/types';
import { fetchStates } from './fetchStates';

const DEFAULT_SEARCH_PARAMS = {
  artist: null,
  artistQuery: '',
  fetchState: '',
  message: '',
  resultsFound: false,
  tracks: [],
  userCompletedSearch: false,
};

const searchReducer = (state = DEFAULT_SEARCH_PARAMS, action) => {
  switch (action.type) {
    case ARTIST_SEARCH.SEARCH_RESULTS_FOUND:
      return {
        ...state,
        artist: action.artist,
        fetchState: fetchStates.success,
        resultsFound: true,
        tracks: action.tracks,
        userCompletedSearch: true,
      };
    case ARTIST_SEARCH.NO_SEARCH_RESULTS:
      return {
        ...state,
        artist: null,
        fetchState: fetchStates.success,
        resultsFound: false,
        tracks: [],
        userCompletedSearch: true,
      };
    case ARTIST_SEARCH.FETCH_ERROR:
      return {
        ...state,
        message: action.message,
        fetchState: fetchStates.error,
        userCompletedSearch: true,
      };
    case ARTIST_SEARCH.USER_TYPED_INTO_SEARCH:
      return {
        ...state,
        artistQuery: action.artistQuery,
      };
    case ARTIST_SEARCH.TRY_AGAIN:
      return {
        ...state,
        artistQuery: '',
        fetchState: '',
        message: '',
        userCompletedSearch: false,
      };
    case ARTIST_SEARCH.SEARCH_STARTED:
      return {
        ...state,
        fetchState: fetchStates.fetching,
      };
    default:
      return state;
  }
};

export default searchReducer;
