import React from 'react';
import { NavLink } from 'react-router-dom';

export const LinkGenerator = (props) => {
  const { desc, path, showInNav } = props.navigationLink;

  if (!showInNav) {
    return null;
  }

  if (desc === 'My Resume') {
    return (
      <li>
        <NavLink
          to='route'
          onClick={(event) => {
            event.preventDefault();
            window.open(path);
          }}
        >
          {desc}
        </NavLink>
      </li>
    );
  }

  return (
    <li className=''>
      <NavLink to={path}>{desc}</NavLink>
    </li>
  );
};
