import React from 'react';
import Flip from 'react-reveal/Flip';
import { NavLink } from 'react-router-dom';
// Global library
import { LinkGenerator } from '../interfaces/LinkGenerator';
import { NavDrawerToggleButton } from './NavDrawerToggleButton';
import { ROUTES } from '../../data/routes';

export const Navbar = (props) => (
  <React.Fragment>
    <div className='siteTitle'>
      <NavLink to='/'>
        <Flip left cascade>
          Nate Richardson
        </Flip>
      </NavLink>

      <NavDrawerToggleButton click={props.drawerClickHandler} />
    </div>

    <div className='mobileNavSeparator'></div>
    <nav className='siteNavigation cf' role='navigation'>
      <ul id='pagesNav' className='listPages'>
        {ROUTES.map((ROUTE) => (
          <LinkGenerator key={ROUTE.id} navigationLink={ROUTE} />
        ))}
      </ul>
    </nav>
  </React.Fragment>
);
