import React, { Component } from 'react';
import Flip from 'react-reveal/Flip';
// Global library
import { ContentContainerDiv } from '../Containers';

const TITLES = [
  'Driven to succeed, to build and to lead.',
  'Passion to create, to design and to amaze!',
  'Driven to succeed, to build and to lead.',
  'Passion to create, to design and to amaze!',
];

class Title extends Component {
  state = { titleIndex: 0, fadeIn: true };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ fadeIn: false });
    }, 2000);
    this.animateTitles();
  }

  componentWillUnmount() {
    clearInterval(this.titleInterval);
    clearTimeout(this.timeout);
  }

  animateTitles = () => {
    this.titleInterval = setInterval(() => {
      const titleIndex = (this.state.titleIndex + 1) % TITLES.length;

      this.setState({ titleIndex, fadeIn: true });

      this.timeout = setTimeout(() => {
        this.setState({ fadeIn: false });
      }, 2000);
    }, 4000);
  };

  render() {
    const { fadeIn, titleIndex } = this.state;
    const title = TITLES[titleIndex];

    return (
      <Flip bottom>
        <ContentContainerDiv>
          <h3 className={fadeIn ? 'fadeIn' : 'fadeOut'}>{title}</h3>
        </ContentContainerDiv>
      </Flip>
    );
  }
}

export default Title;
