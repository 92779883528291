import { MOBILE_WEF_IMAGES } from "../mobileWef";

export const MOBILE_WEF_DATA = {
  title: "Web Quote Form",
  desc: [
    {
      id: 1,
      text:
        "A web insurance quote request form that interfaces with an insurance agent's quoting software via an API. "
    },
    {
      id: 3,
      text: "Features:"
    },
    {
      id: 4,
      text: "* Responsive design - works on mobile phones and tablets"
    },
    {
      id: 5,
      text: "* Request quotes for up to 3 drivers and vehicles."
    },
    {
      id: 6,
      text: "* Emails client when form is submitted"
    },
    {
      id: 7,
      text: "* Easily customizable with dynamic text, header and footers"
    }
  ],
  previews: [
    {
      id: 1,
      alt: "Introductory page - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef1,
      src: MOBILE_WEF_IMAGES.mobileWef1Full
    },
    {
      id: 2,
      alt: "Contact page - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef2,
      src: MOBILE_WEF_IMAGES.mobileWef2Full
    },
    {
      id: 3,
      alt: "Auto quote - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef3,
      src: MOBILE_WEF_IMAGES.mobileWef3Full
    },
    {
      id: 4,
      alt: "Add vehicle page - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef4,
      src: MOBILE_WEF_IMAGES.mobileWef4Full
    },
    {
      id: 5,
      alt: "Review page - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef5,
      src: MOBILE_WEF_IMAGES.mobileWef5Full
    },
    {
      id: 6,
      alt: "Review page - mobile",
      preview: MOBILE_WEF_IMAGES.mobileWef6,
      src: MOBILE_WEF_IMAGES.mobileWef6Full
    },
    {
      id: 7,
      alt: "Home quote - desktop",
      preview: MOBILE_WEF_IMAGES.mobileWef7,
      src: MOBILE_WEF_IMAGES.mobileWef7Full
    }
  ]
};

export default MOBILE_WEF_DATA;
