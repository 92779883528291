import React from 'react';
import Slide from 'react-reveal/Slide';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// Global Library
// Module Library
import { playAudio, stopAudio } from '../../actions/audioPlayer';

export const Tracks = ({ tracks }) => {
  if (tracks.length === 0) return null;

  const dispatch = useDispatch();

  const { audioPlayer, playing, playingPreviewUrl } = useSelector(
    (state) => ({
      audioPlayer: state.audioPlayer.audioPlayer,
      playing: state.audioPlayer.playing,
      playingPreviewUrl: state.audioPlayer.playingPreviewUrl,
    }),
    shallowEqual
  );

  const handlePlayButton = (previewUrl) => () => {
    if (!previewUrl) return null;

    const audio = new Audio(previewUrl);

    if (!playing) {
      audio.play();
      dispatch(playAudio(audio, previewUrl));
    } else {
      audioPlayer.pause();
      if (playingPreviewUrl === previewUrl) {
        dispatch(stopAudio());
      } else {
        audio.play();
        dispatch(playAudio(audio, previewUrl));
      }
    }
  };

  const trackIcon = (track) => {
    if (!track.preview_url) {
      return <span>n/a</span>;
    }

    if (playing && playingPreviewUrl === track.preview_url) {
      return <span>&#9724;</span>;
    }

    return <span>&#9654;</span>;
  };

  return (
    <Slide right>
      <div id='topTracks'>
        <h3>Top 10 Tracks:</h3>
        <div className='trackContainer'>
          {tracks.map((track) => {
            const { id, name, album, preview_url } = track;
            return (
              <div
                key={id}
                onClick={handlePlayButton(preview_url)}
                className='track'
              >
                <img
                  className='trackImage'
                  src={album.images[0].url}
                  alt='track'
                />
                <p className='trackIcon'>{trackIcon(track)}</p>
                <p className='trackText'>{name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Slide>
  );
};
