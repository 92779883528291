import React from 'react';
// Global library
import { Footer } from './Footer';
import { Header } from './Header';
import { InternetExplorerLanding } from './InternetExplorerLanding';

export const SiteWrapper = ({ children }) => {
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  if (isIE) {
    return (
      <div id='siteWrapper'>
        <InternetExplorerLanding />
      </div>
    );
  } else {
    return (
      <div id='siteWrapper'>
        <Header />
        {children}
        <Footer />
      </div>
    );
  }
};
