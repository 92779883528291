import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// Global library
import { ROUTES } from './data/routes';
import { SiteWrapper } from './components/layouts/SiteWrapper';
import './assets/scss/global.scss';
// Module library

ReactDOM.render(
  <Router history={createBrowserHistory()}>
    <Switch>
      {ROUTES.map((ROUTE) => (
        <Route
          key={ROUTE.id}
          exact
          path={ROUTE.path}
          render={() => <SiteWrapper>{ROUTE.component}</SiteWrapper>}
        />
      ))}
    </Switch>
  </Router>,
  document.getElementById('app')
);
