import { INVENTORY_MANAGEMENT_IMAGES } from "./inventoryManagementImages/";

export const INVENTORY_MANAGEMENT_DATA = {
  title: "Inventory Management",
  desc: [
    {
      id: 1,
      text:
        "I developed this Inventory Management System web app for a Gold Canyon Consultant.They shared it with other consultants and I now have 4 consultants using it."
    },
    {
      id: 2,
      text:
        "Allows Consultants to track on hand inventory, manage pricing and streamline tax reporting."
    },
    {
      id: 3,
      text: "App is designed with Responsive technologies for use on tablets."
    },
    {
      id: 4,
      text:
        "App is built using Ruby on Rails, Bootstrap and a CI/ CD pipeline with Heroku and CircleCI."
    },
    {
      id: 5,
      text:
        "Below are a few screenshots of the basic areas of the app.A live demo link and user can be provided if desired."
    }
  ],
  previews: [
    {
      id: 1,
      alt:
        "The full inventory page, using the datatables plugin. Includes search, pagination and admin features.",
      preview: INVENTORY_MANAGEMENT_IMAGES.inventMgmt1,
      src: INVENTORY_MANAGEMENT_IMAGES.inventMgmt1Full
    },
    {
      id: 2,
      alt:
        "Party View - For consultants to access inventory while hosting parties. Consultants can look through inventory on their iPad or laptop and not show sensitive pricing data.",
      preview: INVENTORY_MANAGEMENT_IMAGES.inventMgmt2,
      src: INVENTORY_MANAGEMENT_IMAGES.inventMgmt2Full
    },
    {
      id: 3,
      alt: "Add new item page. Includes requirements for valid date entry.",
      preview: INVENTORY_MANAGEMENT_IMAGES.inventMgmt3,
      src: INVENTORY_MANAGEMENT_IMAGES.inventMgmt3Full
    },
    {
      id: 4,
      alt: "Consultants can edit existing items and prices as needed.",
      preview: INVENTORY_MANAGEMENT_IMAGES.inventMgmt4,
      src: INVENTORY_MANAGEMENT_IMAGES.inventMgmt4Full
    },
    {
      id: 5,
      alt:
        "When consultants need to update a price on many items at once, they can use the Price Adjuster module to do so.",
      preview: INVENTORY_MANAGEMENT_IMAGES.inventMgmt5,
      src: INVENTORY_MANAGEMENT_IMAGES.inventMgmt5Full
    }
  ]
};

export default INVENTORY_MANAGEMENT_DATA;
