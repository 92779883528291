import React, { Component } from 'react';
// Global library
import { Backdrop } from './Backdrop';
import { Navbar } from '../interfaces/Navbar';
import { NavDrawer } from '../interfaces/NavDrawer';

export class Header extends Component {
  state = {
    NavDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { NavDrawerOpen: !prevState.NavDrawerOpen };
    });
  };

  render() {
    return (
      <header id='header' className='siteHeader'>
        <Navbar drawerClickHandler={this.drawerToggleClickHandler} />
        <NavDrawer
          drawerClickHandler={this.drawerToggleClickHandler}
          show={this.state.NavDrawerOpen}
        />
        <Backdrop show={this.state.NavDrawerOpen} />
      </header>
    );
  }
}
