import evenOdd from '../assets/images/evenOddPreview.png';
import jokes from '../assets/images/jokesPreview.png';
import news from '../assets/images/latestNewsPreview.png';
import spotify from '../assets/images/spotifyArtistSearch.png';

const REACT_APPS = [
  {
    id: 1,
    title: 'Jokes',
    path: '/jokes',
    image: jokes,
  },
  {
    id: 2,
    title: 'Spotify Artist Search',
    path: '/spotify-artist-search',
    image: spotify,
  },
  {
    id: 3,
    title: 'Latest News Feeds',
    path: '/latest-news-feed',
    image: news,
  },
  {
    id: 4,
    title: 'Even or Odd Card Game',
    path: '/even-or-odd',
    image: evenOdd,
  },
];

export default REACT_APPS;
