import { Jokes } from '../modules/jokes';
import { LandingPage } from '../modules/landing-page';
import { MeetNate } from '../modules/meet-nate';
import { MusicMaster } from '../modules/music-master';
import { ReactApps } from '../modules/react-apps';
import { RouteNotFound } from '../modules/route-not-found';
import { WebApps } from '../modules/web-apps';
import EvenOrOdd from '../modules/even-or-odd';
import InventoryManagement from '../modules/web-apps/components/InventoryManagement';
import MobileWef from '../modules/web-apps/components/MobileWef';
import NewsFeed from '../modules/news-feed';
import PanTech from '../modules/web-apps/components/PanTech';
import ProjectTracker from '../modules/web-apps/components/ProjectTracker';

export const ROUTES = [
  {
    id: 1,
    component: <LandingPage />,
    desc: 'Home',
    showInNav: false,
    path: '/',
  },
  {
    id: 2,
    component: <MeetNate />,
    desc: 'Meet Nate',
    showInNav: true,
    path: '/meet-nate',
  },
  {
    id: 3,
    component: <ReactApps />,
    desc: 'React Apps',
    showInNav: true,
    path: '/react-projects',
  },
  {
    id: 4,
    component: <WebApps />,
    desc: 'Web Apps',
    showInNav: true,
    path: '/web-apps',
  },
  {
    id: 5,
    component: 'N/A',
    desc: 'My Resume',
    showInNav: true,
    path: 'http://natrixx.com/images/nate_richardson_resume.pdf',
  },
  {
    id: 6,
    component: <Jokes />,
    desc: 'Joke of the day',
    showInNav: false,
    path: '/jokes',
  },
  {
    id: 7,
    component: <MusicMaster />,
    desc: 'Spotify Search',
    showInNav: false,
    path: '/spotify-artist-search',
  },
  {
    id: 8,
    component: <EvenOrOdd />,
    desc: 'Even or Odd',
    showInNav: false,
    path: '/even-or-odd',
  },
  {
    id: 9,
    component: <NewsFeed />,
    desc: 'News Feed',
    showInNav: false,
    path: '/latest-news-feed',
  },
  {
    id: 10,
    component: <InventoryManagement />,
    desc: 'test',
    showInNav: false,
    path: '/inventory-management',
  },
  {
    id: 11,
    component: <PanTech />,
    desc: 'test',
    showInNav: false,
    path: '/panology-website',
  },
  {
    id: 12,
    component: <ProjectTracker />,
    desc: 'test',
    showInNav: false,
    path: '/project-tracker',
  },
  {
    id: 13,
    component: <MobileWef />,
    desc: 'test',
    showInNav: false,
    path: '/web-entry-form',
  },
  {
    id: 14,
    component: <RouteNotFound />,
    desc: 'Not Found',
    showInNav: false,
    path: '*',
  },
];
