import React from 'react';
// Global library
import {
  ButtonWrapperDiv,
  ContentContainerDiv,
  ContentDiv,
} from '../Containers';
import { ContentTitle } from './ContentTitle';
import { StandardButton } from '../interfaces/StandardButton';

export const ErrorMessageLayout = ({ message, onClick, title }) => {
  return (
    <ContentDiv>
      <ContentTitle title={title} />
      <ContentContainerDiv>
        <h2>Error Message: {message}</h2>
        <ButtonWrapperDiv>
          <StandardButton onClick={onClick} text='Try Again' />
        </ButtonWrapperDiv>
      </ContentContainerDiv>
    </ContentDiv>
  );
};
