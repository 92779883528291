import invMgmt from '../assets/images/inventoryMgmt.png';
import mobileWef from '../assets/images/mobileWef.png';
import panTech from '../assets/images/panTech.png';
import projTracker from '../assets/images/ptWebApp.png';

const WEB_APPS = [
  {
    id: 1,
    title: 'Inventory Management',
    path: '/inventory-management',
    image: invMgmt,
  },
  {
    id: 2,
    title: 'Panology Website',
    path: '/panology-website',
    image: panTech,
  },
  {
    id: 3,
    title: 'Project Tracker',
    path: '/project-tracker',
    image: projTracker,
  },
  {
    id: 4,
    title: 'Mobile Web Entry Form',
    path: '/web-entry-form',
    image: mobileWef,
  },
];

export default WEB_APPS;
