import projTrack1 from "./projTrack1.png";
import projTrack2 from "./projTrack2.png";
import projTrack3 from "./projTrack3.png";
import projTrack4 from "./projTrack4.png";
import projTrack5 from "./projTrack5.png";
import projTrack6 from "./projTrack6.png";
import projTrack7 from "./projTrack7.png";
import projTrack8 from "./projTrack8.png";
import projTrack1Full from "./projTrack1Full.png";
import projTrack2Full from "./projTrack2Full.png";
import projTrack3Full from "./projTrack3Full.png";
import projTrack4Full from "./projTrack4Full.png";
import projTrack5Full from "./projTrack5Full.png";
import projTrack6Full from "./projTrack6Full.png";
import projTrack7Full from "./projTrack7Full.png";
import projTrack8Full from "./projTrack8Full.png";

export const PROJECT_TRACKER_IMAGES = {
  projTrack1: projTrack1,
  projTrack2: projTrack2,
  projTrack3: projTrack3,
  projTrack4: projTrack4,
  projTrack5: projTrack5,
  projTrack6: projTrack6,
  projTrack7: projTrack7,
  projTrack8: projTrack8,
  projTrack1Full: projTrack1Full,
  projTrack2Full: projTrack2Full,
  projTrack3Full: projTrack3Full,
  projTrack4Full: projTrack4Full,
  projTrack5Full: projTrack5Full,
  projTrack6Full: projTrack6Full,
  projTrack7Full: projTrack7Full,
  projTrack8Full: projTrack8Full
};

export default PROJECT_TRACKER_IMAGES;
