import React from 'react';
import Flip from 'react-reveal/Flip';
// Global library
import { SOCIAL_PROFILES } from '../../data/socialProfiles';

const SocialProfile = (props) => {
  const { desc, link, image } = props.socialProfile;

  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <img src={image} alt={desc} />
    </a>
  );
};

export const Footer = () => (
  <footer id='footer' className='siteFooter'>
    <Flip bottom delay={1000}>
      {SOCIAL_PROFILES.map((SOCIAL_PROFILE) => (
        <SocialProfile key={SOCIAL_PROFILE.id} socialProfile={SOCIAL_PROFILE} />
      ))}
    </Flip>
  </footer>
);
