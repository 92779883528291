import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TITLE } from '../../actions/settings';
import {
  ContentContainerDiv,
  ContentDiv,
} from '../../../../components/Containers';
import { ContentTitle } from '../../../../components/layouts/ContentTitle';
import GameBoard from './GameBoard';
import StartGameButton from '../interfaces/StartGame';

class GameBoardContainer extends Component {
  render() {
    return (
      <ContentDiv>
        <ContentTitle title={TITLE} />
        {!this.props.gameStarted ? <StartGameButton /> : <GameBoard />}
      </ContentDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    settings: { gameStarted },
  } = state;

  return { gameStarted };
};

const componentConnector = connect(mapStateToProps);

export default componentConnector(GameBoardContainer);
