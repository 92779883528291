import styled from "styled-components";

export default styled.button`
  z-index: 10;
  background: none;
  border-style: none;
  font-size: 50px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: rgb(93, 52, 72);
  transition: color 0.2s linear;
  :hover {
    color: rgb(31, 68, 124);
  }
  :focus {
    outline: none;
    color: rgb(31, 68, 124);
  }
`;
