const MEET_NATE_BIO = [
  {
    id: 1,
    text:
      "Nate is always looking for opportunities to grow as a Front-End or Web Application Developer. He loves and thrives on building ideas from the ground up, translating a client’s vision, managing a talented team and having a finished product that exceeds the client’s desires."
  },
  {
    id: 2,
    text:
      "In 2006, Nate obtained his Associates degree in Audio Engineering, with honors, from The Art Institute of Seattle. After graduation, he worked as an Operations Manager at a recording studio in Southern California, sharpening his chops as a record producer and project manager, as well as his leadership abilities."
  },
  {
    id: 3,
    text:
      "As of September 2019, Nate is currently working as a Front-End Developer for Elektrobit. He is part of an international team developing user interfaces for automotive infotainment systems, using the latest in JavaScript, CSS and HTML5 technologies."
  },
  {
    id: 4,
    text:
      "Previously, Nate worked for the marketing agency DP+ as a Web/Voice Application Developer. In his two years with the agency he developed voice applications for Alexa and Google Assistant. This allowed him the opportunity to learn some new technologies like Jovo and Bespoken, as well as further expanding his AWS skill set to include tools such as Lambda, DynamoDB, CloudWatch and S3."
  },
  {
    id: 5,
    text:
      "In addition, he developed and launched an online asset management tool for a client, creating a central repository for 100 users to find and share marketing materials."
  },
  {
    id: 6,
    text:
      "While with Applied Systems as an Integration Specialist, Nate was responsible for mapping data from an Applied API to third party APIs. Tools used included various JavaScript libraries and testing frameworks such as Mocha. He sharpened many of his skills in this position including effective code reviews, building robust tests for functions, and focus on writing simple and readable code."
  },
  {
    id: 7,
    text:
      "Nate also had the opportunity to update internal web apps to streamline Integration Specialist workflows and improve overall user experience and product quality. In most cases, he has had to dig into the code and learn what was needed as the previous developer was no longer with the company. The apps utilize Ruby on Rails, HTML/CSS, JavaScript and AngularJS."
  },
  {
    id: 8,
    text:
      "In a previous role, he built a support team from the ground up, focused on project management for new client implementation and ongoing support. This team was responsible for assisting doctors in their transition to digital, paperless medical records. To fill a business need, Nate also designed and built a web app for client billing which helped to increase productivity and streamline the workflow of his team."
  },
  {
    id: 9,
    text:
      "He currently resides in Westland, Michigan with his amazing wife, Jodie, and their two dogs, Snickers and Daisy. In his spare time, Nate likes creating music, building model cars, watching college football, and looking for the next item to add to his Batman collection."
  }
];

export default MEET_NATE_BIO;
