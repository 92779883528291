export const ButtonWrapperDiv = ({ children }) => {
  return <div className='buttonWrapper'>{children}</div>;
};

export const ContentContainerDiv = ({ children }) => {
  return <div className='contentContainer'>{children}</div>;
};

export const ContentDiv = ({ children }) => {
  return <div className='content'>{children}</div>;
};

export const ProjectsContainerDiv = ({ children }) => {
  return <div id='projectsContainer'>{children}</div>;
};

export const ProjectContainerDiv = ({ children }) => {
  return <div className='project'>{children}</div>;
};
