// Global library
import { ROUTES } from '../../data/routes';
import { LinkGenerator } from './LinkGenerator';

export const NavDrawer = (props) => {
  let drawerClasses = 'navDrawer';

  if (props.show) {
    drawerClasses = 'navDrawer open';
  }

  return (
    <nav className={drawerClasses}>
      <button onClick={props.drawerClickHandler}>
        <div id='closeButtonBox'>
          <div className='closeButtonLineBottomLeftToTopRight'>
            <div className='closeButtonLineTopLeftToBottomRight'></div>
          </div>
        </div>
      </button>

      <ul id='pagesNav' className='listPages'>
        {ROUTES.map((ROUTE) => (
          <LinkGenerator key={ROUTE.id} navigationLink={ROUTE} />
        ))}
      </ul>
    </nav>
  );
};
