import { DECK } from './types';
import { API_ADDRESS } from '../../../data/globalsVariables';

export const fetchDeckError = (error) => {
  return { type: DECK.FETCH_ERROR, message: error.message };
};

export const fetchDeckSuccess = (deckJson) => {
  const { remaining, deck_id } = deckJson;

  return { type: DECK.FETCH_SUCCESS, remaining, deck_id };
};
export const fetchNewDeck = () => (dispatch) => {
  return fetch(`${API_ADDRESS}/v1/even-or-odd/deck/new/shuffle`)
    .then((response) => {
      if (response.status === 429) {
        throw new Error('Too many requests, please check back in 15 mins');
      } else if (response.status !== 200) {
        throw new Error('Unsuccessful request to deck of cards api');
      }

      return response.json();
    })
    .then((json) => dispatch(fetchDeckSuccess(json)))
    .catch((error) => dispatch(fetchDeckError(error)));
};
