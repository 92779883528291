import Fade from 'react-reveal/Fade';

export const NavDrawerToggleButton = (props) => (
  <Fade right cascade>
    <button className='navDrawerToggleButton' onClick={props.click}>
      <div className='toggleButtonLine' />
      <div className='toggleButtonLine' />
      <div className='toggleButtonLine' />
    </button>
  </Fade>
);
