import { AUDIO_PLAYER } from "../actions/types";

const DEFAULT_PLAYER = {
  playing: false,
  audioPlayer: null,
  playingPreviewUrl: null
};

const audioPlayerReducer = (state = DEFAULT_PLAYER, action) => {
  switch (action.type) {
    case AUDIO_PLAYER.PLAY_AUDIO:
      return {
        ...state,
        audioPlayer: action.audioPlayer,
        playing: true,
        playingPreviewUrl: action.playingPreviewUrl
      };
    case AUDIO_PLAYER.STOP_AUDIO:
      return {
        ...state,
        playing: false
      };

    default:
      return state;
  }
};

export default audioPlayerReducer;
