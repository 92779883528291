import React from 'react';
import Slide from 'react-reveal/Slide';
// Global library
import { ContentContainerDiv, ContentDiv } from '../Containers';
import { ContentTitle } from './ContentTitle';

export const InternetExplorerLanding = () => {
  return (
    <ContentDiv>
      <ContentTitle title='Nate Richardson' />
      <Slide bottom>
        <ContentContainerDiv>
          <p>This site does not support the use of Internet Explorer. </p>
        </ContentContainerDiv>
      </Slide>
    </ContentDiv>
  );
};
