import React from 'react';
// Global library
import { ContentContainerDiv, ContentDiv } from '../../components/Containers';
import { ContentTitle } from '../../components/layouts/ContentTitle';
import { ProjectsLayout } from '../../components/layouts/ProjectsLayout';
// Module library
import WEB_APPS from './data/webApps';

export const WebApps = () => {
  return (
    <ContentDiv>
      <ContentTitle title='Web Apps' />
      <ProjectsLayout projects={WEB_APPS} />
    </ContentDiv>
  );
};
