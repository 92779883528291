import React, { Component } from 'react';
import { TITLE } from '../actions/types';
import { connect } from 'react-redux';
import { reloadAfterError } from '../actions/techNews';
import fetchStates from '../reducers/fetchStates';
import { ErrorMessageLayout } from '../../../components/layouts/ErrorMessageLayout';
import LatestNewsContainer from './layouts/LatestNewsContainer';

class App extends Component {
  render() {
    const { fetchState, message, reloadAfterError } = this.props;

    if (fetchState === fetchStates.error) {
      return (
        <ErrorMessageLayout
          title={TITLE}
          message={message}
          onClick={reloadAfterError}
        />
      );
    }

    return <LatestNewsContainer />;
  }
}

const mapStateToProps = (state) => {
  const {
    news: { fetchState, message },
  } = state;

  return { fetchState, message };
};

const componentConnector = connect(mapStateToProps, { reloadAfterError });

export default componentConnector(App);
