import React, { useState } from 'react';
// Global library
import { ContentDiv } from '../../../components/Containers';
import { ContentTitle } from '../../../components/layouts/ContentTitle';
import { PortfolioLightbox } from '../../../components/interfaces/PortfolioLightbox';
import { ProjectDescription } from '../../../components/layouts/ProjectDescription';
import { ProjectPreviews } from '../../../components/layouts/ProjectPreviews';
// Module library
import { PAN_TECH_DATA } from '../data/panTech';

const PanTech = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const { desc, previews, title } = PAN_TECH_DATA;

  return (
    <ContentDiv>
      <ContentTitle title={title} />
      <ProjectDescription projectDescriptionArray={desc} />
      <ProjectPreviews
        projectPreviewsArray={previews}
        setCurrentIndex={setCurrentIndex}
        onOpen={() => setOpen(true)}
      />
      <PortfolioLightbox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        images={previews}
      />
    </ContentDiv>
  );
};

export default PanTech;
