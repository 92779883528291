import React, { Component } from 'react';
import { ContentContainerDiv } from '../../../../components/Containers';
import DrawnCardTable from './DrawnCardTable';
import Fade from 'react-reveal/Fade';
import PlayerMenu from './PlayerMenu';

class GameBoard extends Component {
  render() {
    return (
      <Fade>
        <ContentContainerDiv>
          <div id='gameBoard'>
            <DrawnCardTable />
            <PlayerMenu />
          </div>
        </ContentContainerDiv>
      </Fade>
    );
  }
}

export default GameBoard;
