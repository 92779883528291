const INTRO_TEXT = [
  {
    id: 1,
    text:
      "Welcome to my web development portfolio, where you will find some projects that I have created or worked on over the last few years."
  },
  {
    id: 2,
    text:
      "The primary technology stack for this site is React JS with Redux and Sass for styling, compiled with Webpack and deployed through Heroku."
  },
  {
    id: 3,
    text:
      "I love all things development, but there is a special place in my heart for Front-End development. Go ahead and take a look around, play with some of the demos, and let me know if I can help you with your next project!"
  }
];

export default INTRO_TEXT;
