import { SET_GAME_STARTED, SET_INSTRUCTIONS_HIDDEN, TRY_AGAIN } from "./types";

export const startGame = () => {
  return { type: SET_GAME_STARTED, gameStarted: true };
};

export const cancelGame = () => {
  return { type: SET_GAME_STARTED, gameStarted: false };
};

export const showInstructions = () => {
  return { type: SET_INSTRUCTIONS_HIDDEN, instructionsHidden: false };
};

export const hideInstructions = () => {
  return { type: SET_INSTRUCTIONS_HIDDEN, instructionsHidden: true };
};

export const TITLE = "Even or Odd";

export const reloadAfterError = () => {
  return { type: TRY_AGAIN };
};
