import githubIcon from '../assets/images/github_icon.png';
import linkedinIcon from '../assets/images/linkedin_icon.png';
import stackOverflowIcon from '../assets/images/so-logo.png';

export const SOCIAL_PROFILES = [
  {
    id: 1,
    desc: 'linked-in-profile',
    link: 'https://www.linkedin.com/in/naterichardson/',
    image: linkedinIcon,
  },
  {
    id: 2,
    desc: 'github-profile',
    link: 'https://github.com/natrixx',
    image: githubIcon,
  },
  {
    id: 3,
    desc: 'stack-overflow-profile',
    link: 'https://stackoverflow.com/story/natrixx',
    image: stackOverflowIcon,
  },
];
