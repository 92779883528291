import React from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { NavLink } from 'react-router-dom';
// Global library
import {
  ContentContainerDiv,
  ProjectContainerDiv,
  ProjectsContainerDiv,
} from '../Containers';

const Project = (props) => {
  const { title, image, path } = props.project;

  return (
    <Flip bottom delay={1000}>
      <ProjectContainerDiv>
        <NavLink to={path}>
          <img className='projectImage' src={image} alt='profile' />
          <Flip left cascade delay={1500}>
            <p className='projectText'>{title}</p>
          </Flip>
        </NavLink>
      </ProjectContainerDiv>
    </Flip>
  );
};

export const ProjectsLayout = ({ projects }) => {
  return (
    <Fade bottom>
      <ContentContainerDiv>
        <ProjectsContainerDiv>
          {projects.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </ProjectsContainerDiv>
      </ContentContainerDiv>
    </Fade>
  );
};
