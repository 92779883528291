import React from 'react';
import { connect } from 'react-redux';
import { setGuessEven, setGuessOdd } from '../../actions/guess';
import { ButtonWrapperDiv } from '../../../../components/Containers';

const Guess = ({ guess, setGuessEven, setGuessOdd }) => {
  return (
    <ButtonWrapperDiv>
      <button
        onClick={setGuessEven}
        className={
          guess === 'even' ? 'standardButton selectedButton' : 'standardButton'
        }
      >
        Even
      </button>
      <button
        onClick={setGuessOdd}
        className={
          guess === 'odd' ? 'standardButton selectedButton' : 'standardButton'
        }
      >
        Odd
      </button>
    </ButtonWrapperDiv>
  );
};

export default connect(({ gameStats: { guess } }) => ({ guess }), {
  setGuessEven,
  setGuessOdd,
})(Guess);
