import { DECK_DRAW, SET_GUESS, SET_GAME_STARTED } from "../actions/types";

const DEFAULT_GAME_STATE = {
  guess: "",
  wasGuessCorrect: "",
  correctGuesses: 0
};
const EVENS = ["2", "4", "6", "8", "0", "QUEEN"];
const ODDS = ["ACE", "3", "5", "7", "9", "JACK", "KING"];

const gameStatReducer = (state = DEFAULT_GAME_STATE, action) => {
  switch (action.type) {
    case SET_GUESS:
      return { ...state, guess: action.guess };
    case SET_GAME_STARTED:
      return DEFAULT_GAME_STATE;
    case DECK_DRAW.FETCH_SUCCESS:
      const { value } = action.cards[0];
      const { guess, correctGuesses } = state;
      if (
        (guess === "even" && EVENS.includes(value)) ||
        (guess === "odd" && ODDS.includes(value))
      ) {
        return {
          ...state,
          wasGuessCorrect: true,
          correctGuesses: correctGuesses + 1
        };
      }

      return {
        ...state,
        wasGuessCorrect: false
      };
    default:
      return state;
  }
};

export default gameStatReducer;
