import React, { Component } from 'react';
import { ContentContainerDiv } from '../Containers';

export default class Toggle extends Component {
  clear() {
    let elem = React.findDOMNode(this);
    let checkedElem = elem.find('input:checked')[0];
    if (checkedElem !== undefined) {
      checkedElem.checked = false;
    }
  }

  handleClick(e) {
    let parentLabel = e.target.parentElement;
    if (parentLabel !== undefined) {
      let value = parentLabel.querySelector('span').innerText;
      if (this.props.onToggle !== undefined) {
        this.props.onToggle(value);
      }
    }
  }

  render() {
    const { items, checked, name } = this.props;

    let radioItems = items.map((item, i) => {
      if (item === checked) {
        return (
          <label key={i}>
            <input
              type='radio'
              defaultChecked
              name={name}
              onClick={this.handleClick.bind(this)}
            />
            <span>{item}</span>
          </label>
        );
      } else {
        return (
          <label key={i}>
            <input
              type='radio'
              name={name}
              onClick={this.handleClick.bind(this)}
            />
            <span>{item}</span>
          </label>
        );
      }
    });

    return (
      <ContentContainerDiv>
        <div className='radioButtonWrapper'>{radioItems}</div>
      </ContentContainerDiv>
    );
  }
}
