import React from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
// Global library
import { ContentContainerDiv } from '../Containers';

const ProjectParagraphs = ({
  index,
  projectPreviewsItem,
  setCurrentIndex,
  onOpen,
}) => {
  const { alt, preview } = projectPreviewsItem;

  const openLightbox = (index) => {
    setCurrentIndex(index);
    onOpen();
  };

  return (
    <Fade bottom delay={1000}>
      <ContentContainerDiv>
        <Flip bottom delay={1500}>
          <div className='projectPreviewContainer'>
            <img
              src={preview}
              alt='previewImage'
              className='projectPreviewImage'
              onClick={() => openLightbox(index)}
            />
            <Fade bottom delay={2000}>
              <p
                className='projectPreviewText'
                onClick={() => openLightbox(index)}
              >
                {alt}
              </p>
            </Fade>
          </div>
        </Flip>
      </ContentContainerDiv>
    </Fade>
  );
};

export const ProjectPreviews = ({
  projectPreviewsArray,
  setCurrentIndex,
  onOpen,
}) => {
  return projectPreviewsArray.map((projectPreviewsItem, index) => (
    <ProjectParagraphs
      key={projectPreviewsItem.id}
      index={index}
      projectPreviewsItem={projectPreviewsItem}
      setCurrentIndex={setCurrentIndex}
      onOpen={onOpen}
    />
  ));
};
