import React from "react";
import { cancelGame } from "../../actions/settings";
import { connect } from "react-redux";
import CancelButton from "../interfaces/CancelButton";
import GameStats from "../interfaces/GameStats";
import GuessButtons from "../interfaces/GuessButtons";
import DrawCardButton from "../interfaces/DrawCardButton";
import InstructionsButton from "../interfaces/InstructionsButton";

const PlayerMenu = ({ cancelGame, guess, remaining }) => {
  return (
    <div id="playerMenu">
      <GameStats />
      <div id="gameButtons">
        {remaining > 0 ? (
          <React.Fragment>
            <GuessButtons />
            {guess ? <DrawCardButton /> : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CancelButton cancelGame={cancelGame} />
          </React.Fragment>
        )}
        <InstructionsButton />
      </div>
    </div>
  );
};

export default connect(
  ({ deck: { remaining }, gameStats: { guess } }) => ({
    guess,
    remaining
  }),
  { cancelGame }
)(PlayerMenu);
