import React from "react";
import { connect } from "react-redux";
import Slide from "react-reveal/Slide";

const EntertainmentNewsDisplay = ({
  entertainmentNews,
  hasEntertainmentNews
}) => {
  return hasEntertainmentNews ? (
    <Slide right cascade>
      <div className="newsContainer">
        <h2>Entertainment Weekly</h2>
        {entertainmentNews.map(article => {
          const { content, id, publishedAt, title, url, urlToImage } = article;

          return (
            <div className="newsArticle" key={id}>
              <div className="articleHeadline">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
                <h4 className="articleContent">
                  {content.split(" [")[0]}
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    read more
                  </a>
                </h4>
                <h5>{new Date(publishedAt).toLocaleString()}</h5>
              </div>
              <div className="articleImage">
                <img src={urlToImage} alt="article" />
              </div>
            </div>
          );
        })}
      </div>
    </Slide>
  ) : null;
};

export default connect(
  ({ news: { entertainmentNews, hasEntertainmentNews } }) => ({
    entertainmentNews,
    hasEntertainmentNews
  })
)(EntertainmentNewsDisplay);
