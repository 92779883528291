import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// Global Library
import { ErrorMessageLayout } from '../../../components/layouts/ErrorMessageLayout';
import { fetchStates } from '../reducers/fetchStates';
// Module Library
import { SpotifyArtistSearchApp } from './layouts/SpotifyArtistSearchApp';
import { TITLE } from '../actions/types';
import { reloadAfterError } from '../actions/search';

export const App = () => {
  const dispatch = useDispatch();

  const { fetchState, message } = useSelector(
    (state) => ({
      fetchState: state.searchResults.fetchState,
      message: state.searchResults.message,
    }),
    shallowEqual
  );

  if (fetchState === fetchStates.error) {
    return (
      <ErrorMessageLayout
        title={TITLE}
        message={message}
        onClick={() => dispatch(reloadAfterError())}
      />
    );
  }

  return <SpotifyArtistSearchApp />;
};
