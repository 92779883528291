import React from 'react';
import Lightbox from 'react-spring-lightbox';
// Global library
import { LightboxArrowButton } from './LightboxArrowButtons';
import { LightboxHeader } from './LightboxHeader';

export const PortfolioLightbox = ({
  images,
  currentImageIndex,
  setCurrentIndex,
  isOpen,
  onClose,
}) => {
  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  return (
    <Lightbox
      isOpen={isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={images}
      currentIndex={currentImageIndex}
      renderHeader={() => (
        <LightboxHeader
          images={images}
          currentIndex={currentImageIndex}
          onClose={onClose}
        />
      )}
      renderPrevButton={({ canPrev }) => (
        <LightboxArrowButton
          position='left'
          onClick={gotoPrevious}
          disabled={!canPrev}
        />
      )}
      renderNextButton={({ canNext }) => (
        <LightboxArrowButton
          position='right'
          onClick={gotoNext}
          disabled={!canNext}
        />
      )}
      className='lightboxContainer'
      onClose={onClose}
    />
  );
};
