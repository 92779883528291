import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchNewDeck } from '../actions/deck';
import { reloadAfterError, startGame, TITLE } from '../actions/settings';
import { ErrorMessageLayout } from '../../../components/layouts/ErrorMessageLayout';
import GameBoardContainer from './layouts/GameBoardContainer';
import fetchStates from '../reducers/fetchStates';

class App extends Component {
  startGame = () => {
    this.props.startGame();
    this.props.fetchNewDeck();
  };

  render() {
    const { fetchState, message, reloadAfterError } = this.props;

    if (fetchState === fetchStates.error) {
      return (
        <ErrorMessageLayout
          title={TITLE}
          message={message}
          onClick={reloadAfterError}
        />
      );
    }

    return <GameBoardContainer />;
  }
}

const mapStateToProps = (state) => {
  const {
    deck: { fetchState, message },
  } = state;

  return { fetchState, message };
};

const componentConnector = connect(mapStateToProps, {
  startGame,
  fetchNewDeck,
  reloadAfterError,
});

export default componentConnector(App);
