import React, { Component } from 'react';
import { TITLE } from '../../actions/types';
import {
  ContentContainerDiv,
  ContentDiv,
} from '../../../../components/Containers';
import { ContentTitle } from '../../../../components/layouts/ContentTitle';
import NewsModuleRenderer from '../interfaces/NewsModuleRenderer';
import ToggleNewsButtons from '../interfaces/ToggleNewsButtons';

class LatestNewsContainer extends Component {
  render() {
    return (
      <ContentDiv>
        <ContentTitle title={TITLE} />
        <ToggleNewsButtons />
        <NewsModuleRenderer />
      </ContentDiv>
    );
  }
}

export default LatestNewsContainer;
