import { API_ADDRESS, ARTIST_SEARCH } from './types';

export const fetchTopTracks = (artistJson, artistId) => (dispatch) => {
  return fetch(`${API_ADDRESS}/v1/spotify-search/artist/${artistId}/top-tracks`)
    .then((response) => {
      if (response.status === 429) {
        throw new Error('Too many requests, please check back in 15 mins');
      } else if (response.status !== 200) {
        throw new Error('Unsuccessful request to Spotify');
      }

      return response.json();
    })
    .then((trackJson) => dispatch(searchSuccess(artistJson, trackJson)))
    .catch((error) => dispatch(searchError(error)));
};

export const noSearchResults = () => {
  return { type: ARTIST_SEARCH.NO_SEARCH_RESULTS };
};

export const reloadAfterError = () => {
  return { type: ARTIST_SEARCH.TRY_AGAIN };
};

export const searchError = (error) => {
  return { type: ARTIST_SEARCH.FETCH_ERROR, message: error.message };
};

export const searchSpotify = (artistQuery) => (dispatch) => {
  return setTimeout(() => {
    fetch(`${API_ADDRESS}/v1/spotify-search/artist/${artistQuery}`)
      .then((response) => {
        if (response.status === 429) {
          throw new Error('Too many requests, please check back in 15 mins');
        } else if (response.status !== 200) {
          throw new Error('Unsuccessful request to Spotify');
        }

        return response.json();
      })
      .then((json) => {
        if (json.artists.total > 0) {
          const artist = json.artists.items[0];

          dispatch(fetchTopTracks(artist, artist.id));
        } else {
          dispatch(noSearchResults());
        }
      })
      .catch((error) => dispatch(searchError(error)));
  }, 1000);
};

export const searchSuccess = (artistJson, trackJson) => {
  return {
    type: ARTIST_SEARCH.SEARCH_RESULTS_FOUND,
    artist: artistJson,
    tracks: trackJson.tracks,
  };
};

export const setFetchingStarted = () => {
  return { type: ARTIST_SEARCH.SEARCH_STARTED };
};

export const updateArtistQuery = (artistQuery) => {
  return {
    type: ARTIST_SEARCH.USER_TYPED_INTO_SEARCH,
    artistQuery,
  };
};
