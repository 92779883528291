import React from 'react';

const Joke = ({ joke: { setup, punchline } }) => (
  <p>
    {setup} <em>{punchline}</em>
  </p>
);

export const JokesLayout = ({ fade, joke, removeFirstJoke }) => {
  return removeFirstJoke ? (
    <div id='jokesContainer' className={fade ? 'fadeOut' : 'fadeIn'}>
      {joke.map((joke) => (
        <Joke key={joke.id} joke={joke} />
      ))}
    </div>
  ) : (
    <div id='jokesContainer' className={fade ? 'fadeOut' : 'fadeIn'}>
      <Joke joke={joke} />
    </div>
  );
};
