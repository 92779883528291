import React from 'react';
import Fade from 'react-reveal/Fade';
import { shallowEqual, useSelector } from 'react-redux';
// Global Library
import { ContentContainerDiv } from '../../../../components/Containers';
// Module Library
import { Artist } from './Artist';
import { SearchResultsContainerDiv } from '../containers';
import { Tracks } from './Tracks';

export const SearchResults = () => {
  const { artist, resultsFound, tracks, userCompletedSearch } = useSelector(
    (state) => ({
      artist: state.searchResults.artist,
      resultsFound: state.searchResults.resultsFound,
      tracks: state.searchResults.tracks,
      userCompletedSearch: state.searchResults.userCompletedSearch,
    }),
    shallowEqual
  );

  if (userCompletedSearch) {
    return (
      <Fade>
        <ContentContainerDiv>
          <SearchResultsContainerDiv>
            {resultsFound ? (
              <>
                <Artist artist={artist} />
                <Tracks tracks={tracks} />
              </>
            ) : (
              <h2>No results found!</h2>
            )}
          </SearchResultsContainerDiv>
        </ContentContainerDiv>
      </Fade>
    );
  }

  return null;
};
