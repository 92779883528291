import React from 'react';
import Flip from 'react-reveal/Flip';
// Global library
import { ContentContainerDiv, ContentDiv } from '../../components/Containers';
import { ContentTitle } from '../../components/layouts/ContentTitle';
import { ProjectsLayout } from '../../components/layouts/ProjectsLayout';
// Module library
import REACT_APPS from './data/reactApps';

export const ReactApps = () => {
  return (
    <ContentDiv>
      <ContentTitle title='React Apps' />
      <Flip bottom>
        <ContentContainerDiv>
          <h3>React Apps I have developed, try one today!</h3>
        </ContentContainerDiv>
      </Flip>
      <ProjectsLayout projects={REACT_APPS} />
    </ContentDiv>
  );
};
