import inventMgmt1 from "./inventMgmt1.png";
import inventMgmt2 from "./inventMgmt2.png";
import inventMgmt3 from "./inventMgmt3.png";
import inventMgmt4 from "./inventMgmt4.png";
import inventMgmt5 from "./inventMgmt5.png";
import inventMgmt1Full from "./inventMgmt1Full.png";
import inventMgmt2Full from "./inventMgmt2Full.png";
import inventMgmt3Full from "./inventMgmt3Full.png";
import inventMgmt4Full from "./inventMgmt4Full.png";
import inventMgmt5Full from "./inventMgmt5Full.png";

export const INVENTORY_MANAGEMENT_IMAGES = {
  inventMgmt1: inventMgmt1,
  inventMgmt2: inventMgmt2,
  inventMgmt3: inventMgmt3,
  inventMgmt4: inventMgmt4,
  inventMgmt5: inventMgmt5,
  inventMgmt1Full: inventMgmt1Full,
  inventMgmt2Full: inventMgmt2Full,
  inventMgmt3Full: inventMgmt3Full,
  inventMgmt4Full: inventMgmt4Full,
  inventMgmt5Full: inventMgmt5Full
};

export default INVENTORY_MANAGEMENT_IMAGES;
