import React from 'react';
// Global library
import { ContentContainerDiv, ContentDiv } from '../../components/Containers';
import { ContentTitle } from '../../components/layouts/ContentTitle';
// Module library
import { Bio } from './layouts/Bio';

export const MeetNate = () => {
  return (
    <ContentDiv>
      <ContentTitle title='Meet Nate' />
      <Bio />
    </ContentDiv>
  );
};
