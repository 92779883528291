import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

export class LoadingSpinner extends Component {
  render() {
    return (
      <div className='loadingSpinner'>
        <Loader type='Circles' color='rgb(93, 52, 72)' height={60} width={60} />
      </div>
    );
  }
}
