import React from 'react';

export const Backdrop = (props) => {
  let backdropClass = '';

  if (props.show) {
    backdropClass = 'open';
  }

  return <div id='backdrop' className={backdropClass}></div>;
};
