import React from "react";
import { cancelGame } from "../../actions/settings";
import { connect } from "react-redux";
import CancelButton from "../interfaces/CancelButton";

const DrawnCardTable = ({ cancelGame, cards, remaining, wasGuessCorrect }) => {
  return (
    <div id="drawCardResults">
      {!cards[0] ? (
        <React.Fragment>
          <h3>No Card Drawn</h3>
          <CancelButton cancelGame={cancelGame} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>
            {cards[0].value} of {cards[0].suit}
          </h3>
          {wasGuessCorrect ? (
            <h5 className="correct">Correct!</h5>
          ) : (
            <h5 className="incorrect">Incorrect</h5>
          )}
          <img className="drawCardImage" src={cards[0].image} alt="card" />
          {remaining === 0 ? null : <CancelButton cancelGame={cancelGame} />}
        </React.Fragment>
      )}
    </div>
  );
};

export default connect(
  ({ deck: { cards, remaining }, gameStats: { wasGuessCorrect } }) => ({
    cards,
    remaining,
    wasGuessCorrect
  }),
  { cancelGame }
)(DrawnCardTable);
