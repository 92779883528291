import panTech1 from "./panTech1.png";
import panTech2 from "./panTech2.png";
import panTech3 from "./panTech3.png";
import panTech4 from "./panTech4.png";
import panTech5 from "./panTech5.png";
import panTech1Full from "./panTech1Full.png";
import panTech2Full from "./panTech2Full.png";
import panTech3Full from "./panTech3Full.png";
import panTech4Full from "./panTech4Full.png";
import panTech5Full from "./panTech5Full.png";

export const PAN_TECH_IMAGES = {
  panTech1: panTech1,
  panTech2: panTech2,
  panTech3: panTech3,
  panTech4: panTech4,
  panTech5: panTech5,
  panTech1Full: panTech1Full,
  panTech2Full: panTech2Full,
  panTech3Full: panTech3Full,
  panTech4Full: panTech4Full,
  panTech5Full: panTech5Full
};

export default PAN_TECH_IMAGES;
