import React from 'react';
import { connect } from 'react-redux';
import { showInstructions, hideInstructions } from '../../actions/settings';

const InstructionsButton = (props) => {
  const { instructionsHidden, showInstructions, hideInstructions } = props;

  if (!instructionsHidden) {
    return (
      <div className='buttonWrapper'>
        <button onClick={hideInstructions} className='standardButton'>
          Toggle Stats
        </button>
      </div>
    );
  }

  return (
    <div className='buttonWrapper'>
      <button onClick={showInstructions} className='standardButton'>
        Toggle Rules
      </button>
    </div>
  );
};

export default connect(
  (state) => ({ instructionsHidden: state.settings.instructionsHidden }),
  { showInstructions, hideInstructions }
)(InstructionsButton);
