import React, { Component } from 'react';
import { NEWS_OPTIONS } from '../../actions/types';
import { connect } from 'react-redux';
import { ContentContainerDiv } from '../../../../components/Containers';
import EntertainmentNewsDisplay from '../layouts/EntertainmentNewsDisplay';
import TechNewsDisplay from '../layouts/TechNewsDisplay';

const { E_WEEKLY, TECH_CRUNCH } = NEWS_OPTIONS;

class NewsModuleRenderer extends Component {
  renderNewsModule(newsModule) {
    switch (newsModule) {
      case TECH_CRUNCH:
        return <TechNewsDisplay />;
      case E_WEEKLY:
        return <EntertainmentNewsDisplay />;
      default:
        return null;
    }
  }

  render() {
    const { showNews, viewNewsModule } = this.props;

    return showNews ? (
      <ContentContainerDiv>
        <div className='newsWrapper'>
          {this.renderNewsModule(viewNewsModule)}
        </div>
      </ContentContainerDiv>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const {
    news: { hasEntertainmentNews, hasTechNews, showNews, viewNewsModule },
  } = state;

  return { hasEntertainmentNews, hasTechNews, showNews, viewNewsModule };
};

const componentConnector = connect(mapStateToProps);

export default componentConnector(NewsModuleRenderer);
